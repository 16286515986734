body {
  @include body-font;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @include heading-font;
}

.btn-xl {
  text-transform: uppercase;
  padding: 1.5rem 3rem;
  font-size: 0.9rem;
  font-weight: 700;
  letter-spacing: 0.1rem;
}

.bg-black {
  background-color: $black !important;
}

.rounded-pill {
  border-radius: 5rem;
}

/*---------------------------------------------------------------------
General
-----------------------------------------------------------------------*/
*::-moz-selection {
  background: #1e3d73;
  color: #ffffff;
  text-shadow: none;
}

::-moz-selection {
  background: #1e3d73;
  color: #ffffff;
  text-shadow: none;
}

::selection {
  background: #1e3d73;
  color: #ffffff;
  text-shadow: none;
}

a,
.btn {
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
}

a,
button,
input,
btn {
  outline: medium none !important;
}

a {
  color: #1e3d73;
}

hr {
  border-color: #f1f1f1;
}

.rounded-circle {
  border-radius: 50% !important;
}

.uppercase {
  text-transform: uppercase;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Manrope", sans-serif;
  font-weight: 500;
  margin: 0px;
  line-height: 1.5;
  color: #3f414d;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
}

label {
  font-weight: normal;
}

h1 {
  font-size: 3.052em;
}

h2 {
  font-size: 2.3em;
}

h3 {
  font-size: 1.953em;
}

h4 {
  font-size: 1.4em;
}

h5 {
  font-size: 1.2em;
}

h6 {
  font-size: 1em;
}

/*----------------------------------------------
Card
------------------------------------------------*/
.iq-card {
  background: #ffffff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  margin-bottom: 30px;
  border: none;
  -webkit-box-shadow: 0px 0px 20px 0px rgba(44, 101, 144, 0.1);
  box-shadow: 0px 0px 20px 0px rgba(44, 101, 144, 0.1);
}

.iq-card-body {
  padding: 15px;
}

.iq-card .iq-card-header {
  padding: 0 20px;
  min-height: 60px;
  -ms-flex-align: center !important;
  align-items: center !important;
  border-bottom: 1px solid #f1f1f1;
}

.iq-card .iq-card-header .iq-header-title {
  display: inline-block;
  align-self: center !important;
}

.iq-card .iq-card-header .iq-header-title .card-title {
  margin-bottom: 0;
}

.iq-card-header-toolbar .nav-item a {
  color: #777d74;
  padding: 4px 12px;
  font-size: 14px;
}

.iq-card-header-toolbar .dropdown-toggle i {
  font-size: 22px;
  line-height: normal;
  color: #777d74;
}

.iq-card-header-toolbar .dropdown-toggle::after {
  display: none;
}

.iq-box-absolute {
  position: absolute;
  left: auto;
  right: -20px;
  top: -15px;
  line-height: 65px;
}

.iq-icon-box {
  height: 60px;
  width: 60px;
  line-height: 60px;
  text-align: center;
  margin-right: 10px;
  font-size: 26px;
  text-decoration: none;
}

/*--------------*/
.nav-pills .nav-item a {
  color: #777d74;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #1e3d73;
  background: #e7ecee;
}

.nav-pills .nav-link:hover {
  color: #1e3d73;
}

/*--------------*/
.nav-tabs {
  border-bottom: 2px solid #e7ecee;
  margin-bottom: 15px;
}

.nav-tabs .nav-item {
  margin-bottom: -2px;
}

.nav-tabs .nav-item a {
  color: #777d74;
  border: none;
  border-bottom: 2px solid transparent;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border-bottom: 2px solid #1e3d73;
  color: #1e3d73;
}

/*----------------------------------------------
Grid Boxes
------------------------------------------------*/
.iq-card-block.iq-card-stretch {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.iq-card-block.iq-card-height-half {
  height: calc(50% - 30px);
}

.iq-card-block.iq-card-height {
  height: calc(100% - 30px);
}

.iq-card-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

/*----------------------------------------------
Background Color Opacity
------------------------------------------------*/
.iq-bg-primary {
  background: #e7ecee !important;
  color: #1e3d73 !important;
}

.iq-bg-secondary {
  background: #f1f2f1 !important;
  color: #a09e9e !important;
}

.iq-bg-success {
  background: #ecfff6 !important;
  color: #65f9b3 !important;
}

.iq-bg-danger {
  background: #ffeeee !important;
  color: #fe517e !important;
}

.iq-bg-warning {
  background: #fefaee !important;
  color: #ffbf43 !important;
}

.iq-bg-info {
  background: #f4f0ff !important;
  color: #9267ff !important;
}

.iq-bg-dark {
  background: #f0efef !important;
  color: #3f414d !important;
}

/*--------------*/
.iq-bg-primary-hover:hover {
  background: #e7ecee !important;
  color: #1e3d73;
}

.iq-bg-primary-secondary-hover:hover {
  background: #f1f2f1 !important;
}

.iq-bg-primary-success-hover:hover {
  background: #ecfff6 !important;
}

.iq-bg-primary-danger-hover:hover {
  background: #ffeeee !important;
}

.iq-bg-primary-warning-hover:hover {
  background: #fefaee !important;
}

.iq-bg-primary-info-hover:hover {
  background: #f4f0ff !important;
}

.iq-bg-primary-dark-hover:hover {
  background: #f0efef !important;
}

.mat-dialog-container {
  padding: 0px !important;
  border-radius: 30px !important;
}


