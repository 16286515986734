
@import "~@openfonts/inter_all/index.css";
@import "~@openfonts/prompt_thai/index.css";

// Noto Sans Thai
@font-face {
  font-family: 'NotoSans';
  src: url('./../font/NotoSansThai/NotoSansThai-Regular.ttf') format('truetype')
}
/*
@font-face {
  font-family: 'NotoSans';
  src: url('./../font/NotoSansThai/NotoSansThai-Bold.ttf') format('truetype')
}
@font-face {
  font-family: 'NotoSans';
  src: url('./../font/NotoSansThai/NotoSansThai-Thin.ttf') format('truetype')
}
@font-face {
  font-family: 'NotoSans';
  src: url('./../font/NotoSansThai/NotoSansThai-ExtraBold.ttf') format('truetype')
}
@font-face {
  font-family: 'NotoSans';
  src: url('./../font/NotoSansThai/NotoSansThai-ExtraLight.ttf') format('truetype')
}
@font-face {
  font-family: 'NotoSans';
  src: url('./../font/NotoSansThai/NotoSansThai-Light.ttf') format('truetype')
}
@font-face {
  font-family: 'NotoSans';
  src: url('./../font/NotoSansThai/NotoSansThai-Medium.ttf') format('truetype')
}
@font-face {
  font-family: 'NotoSans';
  src: url('./../font/NotoSansThai/NotoSansThai-SemiBold.ttf') format('truetype')
}
*/

@mixin heading-font {
  html[lang="en"] & {
    font-family: "Inter";
    font-weight: 600 !important;
  }
  html[lang="th"] & {
    font-family: "NotoSans";
    font-weight: 500 !important;
  }
}
@mixin body-font {
  html[lang="en"] & { font-family: "Inter"; }
  html[lang="th"] & { font-family: "NotoSans"; }
}
