// Variables

// Restated Bootstrap Variables
$primary: #ee0979 !default;
$secondary: #ff6a00 !default;

$white: #fff !default;
$black: #000 !default;

$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;

// Web Colors of Lender Portal
// Primary Color-1
$chess-p1-1: #2C3153 !default;
$chess-p1-2: #414664 !default;
$chess-p1-3: #565A75 !default;
$chess-p1-4: #808398 !default;
$chess-p1-5: #ABADBA !default;
$chess-p1-6: #D5D6DD !default;
$chess-p1-7: #EAEAEE !default;
$chess-p1-8: #F4F5F6 !default;
// Primary Color-2
$chess-p2-1: #265C7E !default;
$chess-p2-2: #517D98 !default;
$chess-p2-3: #7D9DB2 !default;
$chess-p2-4: #A8BECB !default;
$chess-p2-5: #D4DEE5 !default;
// Secondary Color-1
$chess-s1-1: #4B9ABB !default;
$chess-s1-2: #6FAEC9 !default;
$chess-s1-3: #93C2D6 !default;
$chess-s1-4: #B7D7E4 !default;
$chess-s1-5: #DBEBF1 !default;
$chess-s1-6: #EDF5F8 !default;
// Secondary Color-2
$chess-s2-1: #16CAB4 !default;
$chess-s2-2: #45D5C3 !default;
$chess-s2-3: #73DFD2 !default;
$chess-s2-4: #A2EAE1 !default;
$chess-s2-5: #D0F4F0 !default;
$chess-s2-6: #E8FAF8 !default;
// Secondary Color-3
$chess-s3-1: #FBFBFB !default;
$chess-s3-2: #C9C9C9 !default;
$chess-s3-3: #979797 !default;
$chess-s3-4: #646464 !default;
$chess-s3-5: #323232 !default;
// Secondary Color-4
$chess-s4-1: #FFA987 !default;
$chess-s4-2: #FFB79B !default;
$chess-s4-3: #FFC6AF !default;
$chess-s4-4: #FFD4C3 !default;
$chess-s4-5: #FFE2D7 !default;
$chess-s4-6: #FFF1EB !default;
// Secondary Color-5
$chess-s5-1: #F5EFB7 !default;
$chess-s5-2: #FBF6CE !default;
$chess-s5-3: #FCF8D8 !default;
$chess-s5-4: #FDFAE2 !default;
$chess-s5-5: #FDFBEB !default;
$chess-s5-6: #FEFDF5 !default;
// Secondary Color-6
$chess-s6-1: #8BEFC1 !default;
$chess-s6-2: #A2F0CC !default;
$chess-s6-3: #B5F3D6 !default;
$chess-s6-4: #C7F6E1 !default;
$chess-s6-5: #DAF9EB !default;
$chess-s6-6: #ECFCF5 !default;
